import React, { useState } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const ImageBar = () => {

    function SlideShow() {
    const [index, setIndex] = useState(0)
    const { allFile } = useStaticQuery(
        graphql`
        query {
            allFile(
            sort: { fields: name, order: DESC }
            filter: { relativeDirectory: { eq: "slides" } }
            ) {
            edges {
                node {
                id
                name
                childImageSharp {
                    fluid(maxWidth: 980, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
                }
            }
            }
        }
        `
    )
    //Minus 1 for array offset from 0
    const length = allFile.edges.length - 1
    const handleNext = () =>
        index === length ? setIndex(0) : setIndex(index + 1)
    const handlePrevious = () =>
        index === 0 ? setIndex(length) : setIndex(index - 1)

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    shuffleArray(allFile.edges);

    function update() {
        handleNext();

    }
    setTimeout(update, 6000);
    const { node } = allFile.edges[index]
    return (
        <div>
        <div>
            <Img className="imageBar"
            fluid={node.childImageSharp.fluid}
            key={node.id}
            alt={node.name.replace(/-/g, ' ').substring(2)}
            />
        </div>
        </div>
    )
    }
    return SlideShow();
}

export default ImageBar